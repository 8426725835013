<template>
  <template v-if="typeLink === 'base'">
    <a :href="buttonLink" :class="['button-link', buttonClass]">
      <slot/>
    </a>
  </template>
  <template v-else-if="typeLink === 'button'">
    <button class="button-btn" :class="['button-link', buttonClass]">
      <slot/>
    </button>
  </template>
  <template v-else>
    <router-link :to="buttonLink" :class="['button-link', buttonClass]">
      <slot/>
    </router-link>
  </template>
</template>

<script>
export default {
  name: "ButtonLink",
  props: {
    typeLink: {
      type: String,
      default: 'base',
    },
    buttonLink: {
      type: [String, Object],
      default: '/',
    },
    buttonClass: {
      type: [String, Object, Array],
      default: 'sample',
    },
  }
}
</script>

<style lang="scss">
@mixin button {
  border-radius: 27.5px;
  border: none;
  padding: 14px 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s linear;
  width: 100%;
  display: inline-flex;
  justify-content: center;

  &:hover {
    background: #888AFF;
  }

  &:active {
    background: #888AFF;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  @include _576 {
    width: auto;
  }

  @include _768 {
    padding: 19px 49px;
  }
}

.button-link {
  background: #5C5FFF;
  @include button;

  &._secondary {
    color: #707080;
    background: transparent;
    border: 1px solid rgba(112, 112, 127, 0.80);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;

    svg {
      margin-right: 8px;
    }

    svg path {
      transition: all .3s linear;
    }

    &:hover {
      color: #FFFFFF;
      background: #707080;

      svg path {
        fill: #FFFFFF;
      }
    }
  }
}

.button-btn {
  background: #393CEA;
  @include button;
}
</style>
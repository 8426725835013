<template>
  <div class="header-home-banner">
    <div class="row">
      <div class="col-12 col-md-8">
        <img class="img-fluid w-100" src="../../assets/header-home-banner.svg">
      </div>
      <div class="col-12 col-md-7">
        <div class="header-home-banner_desc">
          Команда профессионалов в области корпоративных услуг и безопасности
        </div>
      </div>
      <div class="col-12">
        <button-link button-link="#about-us">
          Подробнее
        </button-link>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLink from "../ButtonLink";

export default {
  name: "HeaderHomeBanner",
  components: {ButtonLink}
}
</script>

<style lang="scss" scoped>
.header-home-banner {
  width: 100%;
  padding: 70px 0 80px 0;

  &_desc {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 30px 0 60px 0;
    @include _768 {
      font-size: 30px;
      line-height: 40px;
    }
  }
}
</style>
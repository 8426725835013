<template>
  <card-element class="contact-wrap">
    <div class="section-title">НАШИ КОНТАКТЫ</div>
    <a class="contact-item" href="tel:+7 999 999-99-99">
      <img class="contact-icon" :src="require('@/assets/phone.svg')">
      <div class="contact-content">
        <div class="contact-main-info">+7 999 999-99-99</div>
        <div class="contact-add-info">С 9:00 - 21:00 по МСК</div>
      </div>
    </a>
    <a class="contact-item" href="mailto:user@usermail.com">
      <img class="contact-icon" :src="require('@/assets/email.svg')">
      <div class="contact-content">
        <div class="contact-main-info">user@usermail.com</div>
        <div class="contact-add-info">Электронная почта</div>
      </div>
    </a>
  </card-element>
</template>

<script>
import CardElement from "./CardElement";

export default {
  name: "ContactBlock",
  components: {CardElement}
}
</script>

<style lang="scss">
.contact-wrap {
  margin: 60px 0 0 0;
  @include _768 {
    margin: 0;
  }
}

.contact-item {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  margin: 0 0 30px 0;

  .contact-icon {
    max-width: 20px;
    width: 100%;
    height: auto;
    margin: 5px 14px 0 0;
    @include _992 {
      max-width: 28px;
      margin: 5px 25px 0 0;
    }
  }

  .contact-main-info {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #454545;
    margin: 0 0 5px 0;
    @include _992 {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .contact-add-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ADB3C5;
    @include _992 {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home-page" */ '../views/Home'),
    },
    {
        path: '/:url',
        name: 'case',
        component: () => import(/* webpackChunkName: "case-page" */ '../views/Case')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {el: to.hash};
        }
        return {top: 0};
    }
})

export default router

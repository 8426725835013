<template>
  <footer class="footer">
    <div class="footer-main">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-lg-3 mb-4">
            <router-link to="/">
              <img class="img-fluid footer-brand" :src="require('@/assets/logo-3.svg')">
            </router-link>
          </div>
          <div class="col-12 col-sm-12 col-lg-9 d-flex flex-column">
            <a class="contact-item mb-4 order-1 order-md-2" href="mailto:user@usermail.com">
              <div class="contact-content">
                <div class="contact-main-info">user@usermail.com</div>
                <div class="contact-add-info">Электронная почта</div>
              </div>
            </a>
            <ul class="navbar-nav _footer order-2 order-md-1">
              <li class="nav-item"
                  v-for="item in navBarArray"
                  :key="item.to" @click="handlerNavbarItem()"
              >
                <template v-if="item.to === '/'">
                  <router-link class="nav-link" to="/">
                    {{ item.name }}
                  </router-link>
                </template>
                <template v-else>
                  <a class="nav-link" :href="item.to">
                    {{ item.name }}
                  </a>
                </template>
              </li>
            </ul>
          </div>
          <!--          <div class="col-12 col-sm-5 col-lg-3 order-sm-2 order-lg-3 d-flex flex-column">-->
          <!--            <button-link button-link="/" button-class="_secondary" class="footer-link ms-auto">-->
          <!--              <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--                <path-->
          <!--                    d="M9.30019 18C9.0615 18 8.83258 17.9052 8.6638 17.7364C8.49502 17.5676 8.4002 17.3387 8.4002 17.1C8.4002 16.8613 8.49502 16.6324 8.6638 16.4636C8.83258 16.2948 9.0615 16.2 9.30019 16.2H16.8002C17.4367 16.2 18.0472 15.9471 18.4972 15.4971C18.9473 15.047 19.2002 14.4365 19.2002 13.8V4.2C19.2002 3.56348 18.9473 2.95303 18.4972 2.50294C18.0472 2.05286 17.4367 1.8 16.8002 1.8H9.30019C9.0615 1.8 8.83258 1.70518 8.6638 1.5364C8.49502 1.36761 8.4002 1.13869 8.4002 0.9C8.4002 0.661305 8.49502 0.432387 8.6638 0.263604C8.83258 0.094821 9.0615 0 9.30019 0H16.8002C17.9141 0 18.9824 0.442499 19.77 1.23015C20.5577 2.0178 21.0002 3.08609 21.0002 4.2V13.8C21.0002 14.9139 20.5577 15.9822 19.77 16.7698C18.9824 17.5575 17.9141 18 16.8002 18H9.30019ZM9.2894 4.4388C9.37622 4.35857 9.47799 4.29623 9.58891 4.25534C9.69983 4.21446 9.81771 4.19582 9.93583 4.2005C10.054 4.20518 10.17 4.23309 10.2773 4.28262C10.3847 4.33216 10.4812 4.40235 10.5614 4.4892L14.1614 8.3892C14.3151 8.55549 14.4004 8.77359 14.4004 9C14.4004 9.22641 14.3151 9.44451 14.1614 9.6108L10.5614 13.5108C10.4811 13.5976 10.3845 13.6676 10.2771 13.7171C10.1698 13.7665 10.0537 13.7943 9.93558 13.7988C9.81747 13.8034 9.69961 13.7847 9.58874 13.7437C9.47786 13.7027 9.37615 13.6403 9.2894 13.56C9.20264 13.4797 9.13256 13.3831 9.08313 13.2757C9.03371 13.1684 9.00592 13.0523 9.00136 12.9342C8.99213 12.6956 9.07804 12.4632 9.24019 12.288L11.4458 9.8988H2.1002C1.8615 9.8988 1.63258 9.80398 1.4638 9.6352C1.29502 9.46641 1.2002 9.23749 1.2002 8.9988C1.2002 8.76011 1.29502 8.53119 1.4638 8.3624C1.63258 8.19362 1.8615 8.0988 2.1002 8.0988H11.4446L9.23899 5.7108C9.15877 5.62398 9.09643 5.5222 9.05554 5.41129C9.01465 5.30037 8.99601 5.18248 9.00069 5.06436C9.00538 4.94624 9.03328 4.8302 9.08282 4.72286C9.13235 4.61553 9.20255 4.519 9.2894 4.4388Z"-->
          <!--                    fill="#70707F"/>-->
          <!--              </svg>-->
          <!--              Личный кабинет-->
          <!--            </button-link>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="text-center text-md-start mb-4 mb-md-0">© 2022 SECURE BUSINESS SOLUTIONS</div>
          </div>
          <div class="col-12 col-md-8">
            <a class="text-center text-md-start d-block" target="_blank"
               href="/docs/SBS-Conf-Policy.pdf"
            >
              Политика об организации обработки и обеспечении безопасности персональных данных
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: "FooterBlock",
  data() {
    return {
      navbar: [
        {
          name: 'ГЛАВНАЯ', to: '/',
        },
        {
          name: 'О НАС', to: '/#about-us',
        },
        {
          name: 'УСЛУГИ', to: '/#basic-services',
        },
        {
          name: 'КОНТАКТЫ', to: '#contacts',
        },
      ],
    }
  },
  computed: {
    navBarArray() {
      return this.$route.name === 'home' ? this.navbar.slice(1) : this.navbar;
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  margin: 80px 0 0 0;

  .footer-main {
    background: #F9F9FF;
    padding: 30px 0 48px 0;
    color: #707080;
  }

  .footer-bottom {
    background: #141414;
    padding: 30px 0 36px 0;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;

    a {
      color: #FFFFFF;
      text-decoration: underline dotted #ADB3C5;
    }
  }

  .footer-brand {
    @include _768 {
      max-width: 129px;
      width: 100%;
    }
  }

  .footer-link {
    display: inline-flex;
    width: 100%;
    @include _576 {
      width: auto;
    }
  }

  .navbar-nav {
    flex-direction: column;

    .nav-item {
      padding: 0 0 24px 0;
    }

    @include _576 {
      flex-direction: row;
      padding: 0 0 24px 0;
      margin: 0 0 24px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .nav-item {
        margin-right: 48px;
        padding: 0;
      }
    }

    .nav-link {
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      @include _768 {
        font-size: 15px;
        line-height: 15px;
      }
    }
  }

  .contact-item {
    @include _768 {
      .contact-main-info {
        font-size: 24px;
        line-height: 29px;
      }
      .contact-add-info {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
</style>
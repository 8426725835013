<template>
  <header-layout/>
  <router-view/>
  <div class="section-page" id="contacts">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-12">
          <div class="section-title">СВЯЗАТЬСЯ С НАМИ</div>
          <div class="h4 text-center text-md-start">Оставьте контактные данные</div>
          <div class="h5 text-center text-md-start">Мы с вами свяжемся.</div>
        </div>
        <div class="col-12 col-md-6">
          <contact-form/>
        </div>
        <div class="col-12 col-md-6 col-xl-5" v-show="false">
          <contact-block class="px-lg-5 py-lg-5"/>
        </div>
      </div>
    </div>
  </div>
  <footer-block/>
</template>
<script>
import store from '@/store';
import HeaderLayout from "./components/header/HeaderLayout";
import ContactForm from "./components/ContactForm";
import FooterBlock from "./components/FooterBlock";
import ContactBlock from "./components/ContactBlock";

export default {
  name: 'App',
  components: {ContactBlock, FooterBlock, ContactForm, HeaderLayout},
  async mounted() {
    await store.dispatch('basicServices/setBasicServices');
    await store.dispatch('cases/setCases');
  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #454545;

  .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .section-page {
    margin: 80px 0 0 0;
    @include _768 {
      margin: 100px 0 0 0;
    }
    .h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #121212;
      margin-bottom: 18px;
      @include _768 {
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
      }
    }
    .h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #454545;
      margin-bottom: 48px;
      @include _768 {
        font-size: 24px;
        line-height: 29px;
        color: #454545;
        margin-bottom: 60px;
      }
    }

    &._bg {
      background: #F9F9FF;
      padding: 48px 0;
      @include _768 {
        padding: 100px 0 80px 0;
      }
    }
  }

  .section-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 3px;
    color: #595959;
    text-transform: uppercase;
    margin: 0 0 24px 0;
    text-align: center;
    @include _768 {
      text-align: start;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 30px 0;
    }
  }
}
</style>

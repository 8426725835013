<template>
  <form @submit.prevent="submitForm" @reset.prevent="resetForm">
    <div :class="['form-group', { error: v$.name.$error }]">
      <label class="form-label">
        <span>ИМЯ</span>
        <input class="form-control"
               placeholder="Введите имя"
               v-model.trim="name"
               @blur="v$.name.$touch"
        >
      </label>
    </div>
    <div :class="['form-group', { error: v$.phone.$error }]">
      <label class="form-label">
        <span>НОМЕР ТЕЛЕФОНА</span>
        <i-mask-component
            class="form-control"
            placeholder='+7 9  _ _  _ _ _  _ _  _ _'
            :mask="'+{7}(000)000-00-00'"
            v-model.trim="phone"
        />
      </label>
    </div>
    <div :class="['form-group', { error: v$.email.$error }]">
      <label class="form-label">
        <span>E-MAIL</span>
        <input class="form-control"
               placeholder="Введите адрес электронной почты"
               v-model.trim="email"
        >
      </label>
    </div>
    <div class="form-group">
      <button-link type-link="button" type="submit" :class="{sending: submitStatus === 'PENDING'}">
        ОТПРАВИТЬ ЗАЯВКУ
        <div class="loading ms-4"/>
      </button-link>
    </div>
    <div class="form-agree">
      Нажимая на кнопку «Отправить заявку», вы соглашаетесь с
      <a href="/docs/SBS-Conf-Policy.pdf" target="_blank">
        политикой конфиденциальности
      </a>
      и даете
      <a href="/docs/SBS-Conf-Policy.pdf" target="_blank">
        согласие на обработку
      </a>
      персональных данных
    </div>
  </form>
  <div class="modal" tabindex="-1" ref="submitStatusModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ statusTitle }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"/>
        </div>
        <div class="modal-body">
          <p>{{ statusSubtitle }}</p>
        </div>
        <div class="modal-footer">
          <button-link type-link="button" data-bs-dismiss="modal">
            Закрыть
          </button-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ButtonLink from "./ButtonLink";
import {Modal} from 'bootstrap';
import {required, minLength, email} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {IMaskComponent} from 'vue-imask';

export default {
  name: "ContactForm",
  components: {ButtonLink, IMaskComponent},
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      info: {
        result: ''
      },
      name: '',
      phone: '',
      email: '',
      formAction: './php/sendmail.php',
      submitStatus: null,
      statusTitle: 'Благодарим за обращение!',
      statusSubtitle: 'Ваше сообщение успешно отправлено. В ближайшее время с вами свяжется администратор.',
    }
  },
  validations() {
    return {
      name: {required, $lazy: true},
      phone: {required, minLength: minLength(15), $lazy: true},
      email: {required, email, $lazy: true},
    }
  },
  methods: {
    showSubmitStatusModal() {
      new Modal(this.$refs.submitStatusModal).show();
    },
    resetForm() {
      this.name = '';
      this.phone = '';
      this.email = '';
      this.v$.$reset();
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.submitStatus = 'ERROR';
      } else {
        this.submitStatus = 'PENDING';

        setTimeout(async (self) => {

          let formData = new FormData();

          formData.append('name', self.name);
          formData.append('phone', self.phone);
          formData.append('email', self.email);

          const response = axios.post(self.formAction, formData)

              .catch(() => {

                self.statusTitle = 'Произошла ошибка';
                self.statusSubtitle = 'Попробуйте отправить форму позже.';
                self.showSubmitStatusModal();
                self.submitStatus = '';

              });

          const respValue = await response;

          self.info = respValue.data;

          if (!!self.info.result && self.info.result === 'success') {

            self.statusTitle = 'Благодарим за обращение!';
            self.statusSubtitle = 'Ваше сообщение успешно отправлено. В ближайшее время с вами свяжется администратор.';
            self.showSubmitStatusModal();
            self.resetForm();

          } else {
            if (self.info.message) {

              self.statusTitle = 'Произошла ошибка';
              self.statusSubtitle = self.info.message;
              self.showSubmitStatusModal();

            } else {

              self.statusTitle = 'Произошла ошибка';
              self.statusSubtitle = 'Попробуйте отправить форму позже.';
              self.showSubmitStatusModal();
            }
          }
          self.submitStatus = '';
        }, 300, this)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  margin: 0 0 30px 0;

  &.error {
    .form-control {
      border-color: #FF365A;
    }

    .form-label span {
      color: #FF365A;
    }
  }

  .form-label {
    width: 100%;
  }

  .form-label span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 3px;
    color: #959DB5;
    @include _768 {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .form-control {
    font-weight: 300;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    border-bottom: 2px solid #ADB3C5;
    padding: 0 0 16px 0;
    outline: none;
    box-shadow: none;
    color: #454545;

    &::placeholder {
      color: #ADB3C5;
    }

    &:focus {
      border-color: #393CEA;
    }

    @include _768 {
      font-size: 24px;
      line-height: 29px;
      padding: 0 0 14px 0;
    }
  }
}

.form-agree {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #807D7D;

  a {
    color: #393CEA;
    border-bottom: 2px dashed rgba(57, 60, 234, 0.65);
    text-decoration: none;
  }

  @include _768 {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
}

.sending {
  pointer-events: none;
  cursor: not-allowed;
  position: relative;

  .loading {
    transition-delay: 0.5s;
    transition-duration: 1s;
    opacity: 1;
  }
}

.loading {
  width: 20px;
  height: 20px;
  border: 2px solid #FFFFFF;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s;
  animation: 1s linear infinite rotate;
  position: absolute;
  right: 15px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
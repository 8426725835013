import {getCases} from "../../api/cases";

const defaultState = () => {
    return {
        cases: [],
        curCase: {},
    }
}
const state = defaultState();

const actions = {
    async setCases({commit}) {
        let response = await getCases();

        commit('SET_CASES', response.data);
    },
    async setCurCase({commit}, data) {

        commit('SET_CUR_CASE', data);
    },
};

const getters = {
    getCaseByUrl: state => url => {
        return state.cases.find(theCase => theCase.url === url);
    },
};

const mutations = {
    SET_CASES(state, array) {
        state.cases = array;
    },
    SET_CUR_CASE(state, obj) {
        state.curCase = obj;
    },
};

export default {
    namespaced: true, state, actions, getters, mutations,
}

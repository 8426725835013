import {createStore} from 'vuex'
import basicServices from "./modules/basicServices";
import cases from "./modules/cases";

export default createStore({
    modules: {
        basicServices,
        cases,
    },
})

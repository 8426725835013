import {getBasicServices} from "../../api/basic-services";

const defaultState = () => {
    return {
        basicServices: [],
    }
}
const state = defaultState();

const actions = {
    async setBasicServices({commit}) {
        let response = await getBasicServices();

        commit('SET_STATE', response.data);
    },
};

const mutations = {
    SET_STATE(state, array) {
        state.basicServices = array;
    },
};

export default {
    namespaced: true, state, actions, mutations,
}

<template>
  <nav class="navbar navbar-expand-md flex-md-wrap"
       :class="{'_show': collapsed, '_secondary': !isRouterNameIs}">
    <div class="navbar-ellipse" v-if="isRouterNameIs"/>
    <div class="container navbar-container align-items-start align-items-md-center">
      <div class="navbar-polygon" v-if="isRouterNameIs"/>
      <router-link class="z-index-up" to="/">
        <img :src="[isRouterNameIs ? require('@/assets/logo-1.svg') : require('@/assets/logo-2.svg')]"
             alt="Команда профессионалов в области корпоративных услуг и безопасности"
             class="navbar-brand"
        >
      </router-link>
      <div :class="['navbar-toggler','z-index-up', {'active': collapsed}]"
           data-bs-toggle="collapse"
           data-bs-target="#navbar"
           @click="handlerToggler()"
      >
        <div class="dash"/>
        <div class="dash"/>
        <div class="dash"/>
        <div class="dash"/>
      </div>
      <div class="collapse navbar-collapse z-index-up" id="navbar" ref="navbarCollapse">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item"
              v-for="item in navBarArray"
              :key="item.to" @click="handlerNavbarItem()"
          >
            <template v-if="item.to === '/'">
              <router-link class="nav-link" to="/">
                {{ item.name }}
              </router-link>
            </template>
            <template v-else>
              <a class="nav-link" :href="item.to">
                {{ item.name }}
              </a>
            </template>
          </li>
        </ul>
      </div>
      <header-home-banner class="z-index-up" v-if="isRouterNameIs"/>
    </div>
  </nav>
</template>

<script>
import {Collapse} from 'bootstrap'
import HeaderHomeBanner from "./HeaderHomeBanner";

export default {
  name: "HeaderLayout",
  components: {HeaderHomeBanner},
  data() {
    return {
      navbar: [
        {
          name: 'ГЛАВНАЯ', to: '/',
        },
        {
          name: 'О НАС', to: '/#about-us',
        },
        {
          name: 'УСЛУГИ', to: '/#basic-services',
        },
        {
          name: 'КОНТАКТЫ', to: '#contacts',
        },
      ],
      collapsed: false,
      window: {
        width: 0,
      }
    }
  },
  computed: {
    isRouterNameIs() {
      return this.$route.name === 'home';
    },
    navBarArray() {
      return this.$route.name === 'home' ? this.navbar.slice(1) : this.navbar;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
    handlerToggler() {
      this.collapsed = !this.collapsed;

      if (this.collapsed) {
        document.querySelector('body').style.overflowY = 'hidden';
      } else {
        document.querySelector('body').style.overflowY = 'auto';
      }
    },
    handlerNavbarItem() {
      if (this.window.width < 768) {
        this.handlerToggler();
        new Collapse(this.$refs.navbarCollapse, {hide: true});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.z-index-up {
  position: relative;
  z-index: 10;
}

.navbar {
  background: #393CEA;
  position: relative;
  padding: 16px 0;

  &._show {
    .navbar-ellipse,
    .navbar-polygon {
      opacity: 0;
    }
  }

  &._secondary {
    background: #FFFFFF;


    .nav-item {
      .nav-link {
        color: #454545;
      }
    }

    .navbar-toggler {
      .dash {
        background: #454545;
      }
    }

    .navbar-collapse {
      background: #FFFFFF;
    }

    .navbar-container {
      &:after {
        content: '';
        width: calc(100% - 32px);
        height: 1px;
        background: rgba(173, 179, 197, 0.3);
        position: absolute;
        bottom: -1rem;
      }
    }
  }

  .navbar-ellipse {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../assets/navbar-ellipse-1.svg") no-repeat center left;
    background-size: contain;
    z-index: 1;
    @include _768 {
      background: url("../../assets/navbar-ellipse-2.svg") no-repeat center left;
    }
  }

  .navbar-polygon {
    width: 45px;
    height: 75px;
    position: absolute;
    top: 105px;
    right: 16px;
    background: url("../../assets/navbar-polygon-1.svg") no-repeat center right;
    background-size: contain;
    z-index: 2;
    @include _768 {
      top: -25px;
      width: 100%;
      height: 100%;
      background: url("../../assets/navbar-polygon-2.svg") no-repeat center right;
    }
  }

  .navbar-container {
    position: relative;
    z-index: 3;
  }

  .nav-item {
    cursor: pointer;
    padding: 0 0 30px 0;
    @include _768 {
      padding: 0 0 0 60px;
    }

    .nav-link {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #FFFFFF;
      padding: 0;
      @include _768 {
        font-size: 15px;
        line-height: 15px;
      }
    }
  }

  .navbar-collapse {
    position: absolute;
    left: 0;
    top: 54px;
    width: 100%;
    height: calc(100vh - 54px);
    background: #393CEA;
    transition: none;
    z-index: 100;
    @include _768 {
      height: auto;
      width: auto;
      position: static;
      background: transparent;
    }

    .navbar-nav {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .navbar-brand {
    padding: 0;
    margin: 0;
    @include _768 {
      width: 129px;
    }
  }

  .navbar-toggler {
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: space-between;
    height: 16px;
    position: relative;
    border: none;
    outline: none;
    box-shadow: none;

    @include _768 {
      display: none;
      position: absolute;
    }

    .dash {
      width: 23px;
      height: 2.5px;
      background: white;
      transition: 0.25s;

      &:nth-of-type(3) {
        position: absolute;
        top: calc(50% - 1.5px);
      }
    }

    &.active {
      .dash {
        &:nth-of-type(1) {
          opacity: 0;
        }

        &:nth-of-type(4) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
</style>
<template>
  <div class="card-element">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "CardElement"
}
</script>

<style lang="scss" scoped>
.card-element {
  border-radius: 16px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
  padding: 20px 24px 36px;
}
</style>